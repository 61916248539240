import React from "react"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/bootstrap.scss"
import "../components/covid.css"

const Covid19USTimeSeries = () => {
  return (
    <Layout>
      <SEO
        title="COVID-19 Time Series Charts for US States"
        keywords={[
          `Tin`,
          `Pan`,
          `Valley`,
          `Consulting`,
          `Software`,
          `Development`,
          `COVID-19`,
        ]}
      />
      <Container className="covid-discontinued">
        <Row>
          <Col md={12}>
            <h2 className="covid my-4">
              Time series Charts of COVID-19 Cases by US State
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>Thanks for visiting.</p>
            <p>
              As pandemic has moved out of the chaotic novel stage where data
              was scarce and into the mass vaccination phase with a new more
              competent Presidential administration, coupled with the fact that
              the COVID Tracking Project is wrapping up their heroic work to
              fill the data void, this service is discontinued.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Covid19USTimeSeries
